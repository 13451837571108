function mobileMenu () {
    const openMenu = document.querySelector('.header__open-menu');
    const closeMenu = document.querySelector('.header__close-menu');
    const mobileMenu = document.querySelector('.header__menu');
    const body = document.querySelector('body');
    const mobileLinks = document.querySelectorAll('.header__menu-list-item')
    openMenu.addEventListener('click', ()=> {
        mobileMenu.classList.add('header__menu--visible');
        body.classList.add('overflow');

    });
    closeMenu.addEventListener('click', () => {
        mobileMenu.classList.remove('header__menu--visible')
        body.classList.remove('overflow')
    })
    mobileLinks.forEach(link => {
        link.addEventListener('click', () => {
            mobileMenu.classList.remove('header__menu--visible')
            body.classList.remove('overflow')
        })
    })
}

module.exports = mobileMenu